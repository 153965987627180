<script setup lang="ts">
    
    /** vue, o365 */
    import { computed, shallowRef, ref, watch, onMounted, onDeactivated } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { confirm } from 'o365-vue-services';
    import MHazard from 'sja.vue.libraries.mob.HazardsMobile.vue';

    /** utils */
    import {    
                procSJAWorkflowAction, procGetMatrix, procCreateSJA, 
                procDeleteSJA, procAddResponsible, procDeleteTask, 
                procRemoveSignature, procSignSJA, modalInitialMatrix, 
                modalFinalMatrix 
                
            } from 'sja.vue.libraries.modules.utilities.js';

    /** mobile */
    import { MContainer, MBottomSheet, MCard } from 'o365-mobile';

    const props = defineProps({
        sjaID: Number,
        readOnly: Boolean,
        comp:[]
    });
    
    let dsTasksLength = computed(() => {
        return dsTasks.getDataLength();
    });
    
    let disableButton = computed (() =>{
        return dsTasks.current?.hasChanges;
    });
    const switcher = ref(null);

    const TaskNo = ref();
    const Description = ref();
    const showBottomSheet = ref(null);
    const ref_newTask = ref(null);

    const Task_ID = ref();

    onMounted(() => {
        dsSJA.load();
        dsTasks.load();
    });

    const dsSJA = getOrCreateDataObject({
        id: `dsSJA_${props.sjaID}`,
        uniqueTable: 'atbl_SJA_SJA',
        maxRecords: -1,
        selectFirstRowOnLoad: true,
        viewName: 'aviw_SJA_SJA',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Step_ID', },
            { name: 'Name', },
            { name: 'Description', },
            { name: 'Planned', },
            { name: 'Completed', },
            { name: 'OrgUnit_ID', },
            { name: 'IdPath', },
            { name: 'OrgUnit', },
            { name: 'Responsible_ID', },
            { name: 'Responsible', },
            { name: 'IsTemplate', },
            { name: 'Checklist_ID', },
            { name: 'CheckList_Editable', },
            { name: 'Checklist', }
        ],
        whereClause: "ID = " + props.sjaID
    });

    const dsTasks = getOrCreateDataObject({
        id: `dsTasks_${props.sjaID}`,
        uniqueTable: 'atbl_SJA_SJATasks',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasks',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'SJA_ID', },
            { name: 'TaskNo', },
            { name: 'Description', },
            { name: 'Responsible_ID', },
            { name: 'Comment', }
        ],
        whereClause: `SJA_ID = ${props.sjaID}`,
        masterDataObject_ID: `dsSJA_${props.sjaID}`,
        masterDetailDefinition: [
            {
                masterField: "ID",
                detailField: "SJA_ID",
                operator: "equals"
            }
        ],
    });

    async function deleteTask(row){
        await confirm({
            title: $t("Delete Task"),
            message: $t(`Are you sure you wish to delete ${row.Description}?`),
            btnTextOk: $t("Yes"),
            btnTextCancel: $t("No"),
        }).then(async () => {
            await procDeleteTask.execute({ PrimKey: row.PrimKey });
            dsTasks.load();
        }).catch(() => {
        });
    } 

    function setIndex(e){
        if(e !== undefined && e.index !== null){
            dsTasks.setCurrentIndex(e.index);
        }
    }

    async function saveChanges(){
        await dsTasks.save();
    }

    function discard(pIndex){
        if(pIndex !== undefined && pIndex !== null){
            dsTasks.cancelChanges(pIndex);
        }
    }

    function createNewTask(){
        let _TaskNo: number;
        let _Description: string;
        _TaskNo = Number(TaskNo.value);
        _Description = Description.value;
        
        if(_TaskNo !== undefined && _Description !== undefined){
            if(_TaskNo.toString() === 'NaN'){
                alert($t(`Invalid input. Please enter a number in TaskNo`), danger, { autohide: true, delay: 3000 });
            }
            dsTasks.createNew({TaskNo: _TaskNo, Description: _Description}, false);
            dsTasks.load();
            bootstrap.Modal.getInstance(document.querySelector('#CreateTaskModal')).hide();
            resetAndDismiss();
        }else{
            alert($t(`Please fill in all fields`), ToastType.Danger, { autohide: true, delay: 3000 });
        }
        
    }

    function onModalShown(){
        document.getElementById('CreateTaskModal').style.zIndex = '10000';
        document.getElementById('CreateTaskModal').style.position='absolute';
    }

    function resetAndDismiss(){
        TaskNo.value = null;
        Description.value = null;
    }

    window.dsTasks = dsTasks;
    
    window.SwitchComponentObj = {
        comp: switcher,
        TaskID: Task_ID,
        sjaid: props.sjaID
    };
    
</script>

<style scoped>

    .taskEditBtn{
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 4px;
        padding-right: 4px;
    }
   .btn-close{
        box-shadow: none;
        font-size: 13px;
   }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .btn-new{
        border-radius: 2.0rem;
        overflow: hidden;
        background-color: #4682B4;
        color: white;
    }
    
</style>

<template>

    <button class="btn btn-outline-primary w-100 dropdown-toggle" @click="showBottomSheet = true">
        {{ $t('Tasks') + ': ' + dsTasksLength }}
    </button>
    
    <MBottomSheet v-model="showBottomSheet" min-height="calc(100vh - 4rem)">

        <template #title>
            <div class="d-flex justify-content-center align-items-center">
                <transition name="fade" mode="out-in">
                    <template v-if="switcher === 'Hazards'">
                        <div style="position: absolute;">
                            {{ switcher }}
                        </div>
                    </template>
                    <template v-else-if="switcher === 'Actions'">
                        <div style="position: absolute;">
                            {{ switcher }}
                        </div>
                    </template>
                    <template v-else>
                        <div style="position: absolute;">
                            {{ $t("Tasks") }}
                        </div>
                    </template>
                </transition>
            </div>
        </template>

        <template #action>
            <transition name="fade" mode="out-in">
                <template v-if="switcher === 'Hazards'">
                    <div class="btn" @click="switcher = null">
                        <i class="bi bi-arrow-left fs-4" style="color: gray"/>
                    </div>
                </template>
                <template v-else-if="switcher ==='Actions'">
                    <div class="btn" @click="switcher = 'Hazards'">
                        <i class="bi bi-arrow-left fs-4" style="color: gray"/>
                    </div>
                </template>
                <template v-else>
                    <div></div> 
                </template>
            </transition>
        </template>

        <template #body>
            <MContainer>
                <transition name="fade" mode="out-in">
                    <template v-if="switcher === 'Hazards'">
                        <MHazard :SJATask_ID ="String(Task_ID)" :sjaID="props.sjaID" :readOnly="props.readOnly"/>
                    </template>
                    <template v-else-if="switcher === 'Actions'">
                        <div> Actions </div>
                    </template>
                    <template v-else>
                        <MContainer class="d-flex align-items-start flex-column mb-3">
                            <div class="p-3 mb-auto">
                                <div class="row g-2">
                                    <template v-for="item in dsTasks.data">
                                        <div class="d-flex" v-if="!item.isNewRecord">
                                            <div class="flex-grow-1">
                                                <MCard> 
                                                    <span @click="switcher = 'Hazards'; Task_ID = item.ID;"> {{ item.TaskNo + '. ' + item.Description }} </span> 
                                                    <div class="btn btn-sm float-end taskEditBtn dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </div>
                                                    <div class="dropdown">
                                                        <ul class="dropdown-menu">
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1" data-bs-toggle="offcanvas" data-bs-target="#showEdit" aria-controls="showEdit" @click="setIndex(item)"> {{ $t('Edit') }} 
                                                                    <i class="bi bi-pencil-square float-end"></i>
                                                                </a>
                                                            </li>
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1 text-danger" @click="deleteTask(item)"> {{ $t('Delete') }} 
                                                                    <i class="bi bi-x float-end"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </MCard>    
                                            </div>
                                        </div>
                                    </template>    
                                    <div class="offcanvas offcanvas-end" tabindex="-1" id="showEdit" aria-labelledby="offcanvasL" @hidden="dsTasks.unsetCurrentIndex()">
                                        <div class="offcanvas-header">
                                            <h5 id="offcanvasL">{{ $t('Editing current task:') }}</h5>
                                            <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div class="offcanvas-body" >
                                            <div class="mb-3">
                                                <label for="ITaskNo" class="form-label">{{ $t('TaskNo') }}</label>
                                                <input id="ITaskNo" class="form-control" v-model="dsTasks.current.TaskNo"/>
                                            </div>
                                            <div class="mb-3">
                                                <label for="IDescription" class="form-label">{{ $t('Description') }}</label>
                                                <input id="IDescription" class="form-control" v-model="dsTasks.current.Description"/>
                                            </div>
                                            <div class="mt-5">
                                                <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="saveChanges()">                  {{ $t('Save') }}  </button>
                                                <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="discard(dsTasks.current.index)"> {{ $t('Cancel') }} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OModal id="CreateTaskModal" class="newtTaskModal" name="NewTask-modal" ref="ref_newTask" @show="onModalShown()">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title"> {{$t('Create New Task')}} </h5>
                                        </div>
                                        <div class="modal-body">
                                            <div class="mb-3">
                                                <label for="NTaskNo" class="form-label">{{ $t('TaskNo') }}</label>
                                                <input id="NTaskNo" class="form-control" v-model="TaskNo"/>
                                            </div>
                                            <div class="mb-3">
                                                <label for="NDescription" class="form-label">{{ $t('Description') }}</label>
                                                <input id="NDescription" class="form-control" v-model="Description"/>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary mx-1" @click="createNewTask()"> {{ $t('Create') }}  </button>
                                            <button type="button" class="btn btn-primary mx-1" data-bs-dismiss="modal" @click="resetAndDismiss()"> {{ $t('Cancel') }} </button>
                                        </div>
                                    </div>
                                </div>
                            </OModal>
                            <div class="d-flex p-3" style="margin-left: auto;">
                                <a class="btn flex-grow-1 btn-new" v-target:NewTask-modal>
                                    <i class="bi bi-plus fs-2 float-end"></i>
                                </a>      
                            </div> 
                        </MContainer>                    
                    </template>
                </transition>
            </MContainer>  
        </template>
    </MBottomSheet>
</template>